import { Grid, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'
import { Layout } from '../components/Layout'
import { Link } from '../components/Link'
import { ListRingImage } from '../components/ListRingImage'
import { SEO } from '../components/SEO'
import { Txt } from '../components/Txt'
import { type MarriageRing } from '../types'
import { numberWithCommas } from '../utils'
import type { RankingQuery } from '../../types/graphql-types'
import type { PageProps } from 'gatsby'

export const Head = () => (
    <SEO
        title="安い結婚指輪・激安マリッジリングの人気指輪ランキングをご紹介｜ミスプラチナ"
        description="低価格の結婚指輪はメーカー直販ミスプラチナがおすすめ。プラチナペアの人気ランキング！"
        page="ranking/marriage"
    />
)

type RankingRing = Pick<
    MarriageRing,
    'name' | 'code' | 'femalePrice' | 'pairPrice'
> & { text: string; images: string[] }

const RankingPage: React.FC<PageProps<RankingQuery>> = ({ data }) => {
    const muiTheme = useTheme()
    const rings = useMemo(() => {
        return data.ranking.edges
            .map((r) => {
                const ring = data.marriageRings.edges.find((ring) => {
                    return ring.node.code === r.node.code
                })
                if (ring) {
                    return {
                        ...ring.node,
                        text: r.node.text,
                    }
                }
                return
            })
            .filter((r): r is RankingRing => {
                return (
                    !!r &&
                    !!r.name &&
                    !!r.code &&
                    !!r.images &&
                    !!r.femalePrice &&
                    !!r.pairPrice &&
                    !!r.text
                )
            })
    }, [data])
    return (
        <Layout breadcrumbs={[{ name: '結婚指輪　人気ランキング' }]}>
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12} component="main">
                    <H1>
                        <StaticImage
                            src="../images/ranking/top.jpg"
                            alt="結婚指輪ランキング"
                        />
                    </H1>
                    <Desc>
                        低価格で高品質のミスプラチナ 結婚指輪人気ランキング！
                    </Desc>
                    {rings.map((ring, index) => {
                        return (
                            <Wrapper theme={muiTheme} key={index}>
                                <H2 theme={muiTheme}>
                                    <Txt size="s">
                                        人気No.{index + 1} {ring.name}
                                    </Txt>
                                </H2>
                                <Body theme={muiTheme}>
                                    <ImageWrapper>
                                        <Link to={'/ring/' + ring.code + '/'}>
                                            <ListRingImage ring={ring} />
                                        </Link>
                                    </ImageWrapper>
                                    <RingDetail>
                                        <Row>
                                            <RowTxt size="s">
                                                {ring.text}
                                            </RowTxt>
                                        </Row>
                                        <Row>
                                            <RowTxt size="s">
                                                【{ring.name}】
                                            </RowTxt>
                                            <RowTxt size="s">
                                                単品
                                                {numberWithCommas(
                                                    ring.femalePrice
                                                )}
                                                円
                                            </RowTxt>
                                            <RowTxt size="s">
                                                （2本ペア：
                                                {numberWithCommas(
                                                    ring.pairPrice
                                                )}
                                                円）
                                            </RowTxt>
                                        </Row>
                                        <Row>
                                            <Link
                                                to={'/ring/' + ring.code + '/'}
                                            >
                                                <Txt size="s">商品詳細へ</Txt>
                                            </Link>
                                        </Row>
                                    </RingDetail>
                                </Body>
                            </Wrapper>
                        )
                    })}
                </Grid>
            </Grid>
        </Layout>
    )
}

export const query = graphql`
    query Ranking($rankingRingCodes: [String]) {
        marriageRings: allMarriageringsJson(
            filter: { code: { in: $rankingRingCodes } }
        ) {
            edges {
                node {
                    code
                    name
                    material
                    femalePrice
                    pairPrice
                    images
                }
            }
        }
        ranking: allRankingJson(sort: { fields: rank, order: ASC }) {
            edges {
                node {
                    code
                    text
                }
            }
        }
    }
`

const H1 = styled('h1')({
    margin: 0,
})
const Desc = styled('p')({
    margin: '8px',
})
const Wrapper = styled('section')(({ theme }) => ({
    margin: '40px 8px 0',
    border: `1px solid ${theme.palette.secondary.main}`,
}))
const H2 = styled('h2')(({ theme }) => ({
    margin: 0,
    padding: '10px',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    lineHeight: 1,
}))
const Body = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
    },
}))
const ImageWrapper = styled('div')({
    minWidth: '190px',
    display: 'flex',
    justifyContent: 'center',
    margin: '12px',
})
const RingDetail = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
})
const Row = styled('div')({
    display: 'flex',
    marginTop: '8px',
    flexWrap: 'wrap',
})
const RowTxt = styled(Txt)({
    marginRight: '4px',
})

export default RankingPage
